/**
Ion.RangeSlider, 2.3.1
© Denis Ineshin, 2010 - 2019, IonDen.com
Build date: 2019-12-19 16:51:02
*/

.irs {
    //Flat Theme
    --cnvs-range-slider-top: 25px;
    --cnvs-range-slider-bottom: 16px;
    --cnvs-range-slider-line_height: 12px;
    --cnvs-range-slider-handle_width: 16px;
    --cnvs-range-slider-handle_height: 18px;
    --cnvs-range-slider-custom_radius: 4px;
    --cnvs-range-slider-line_color: var(--cnvs-contrast-200);
    --cnvs-range-slider-bar_color: var(--cnvs-themecolor);
    --cnvs-range-slider-handle_color_1: var(--cnvs-range-slider-bar_color);
    --cnvs-range-slider-handle_color_2: var(--cnvs-contrast-500);
    --cnvs-range-slider-minmax_text_color: var(--cnvs-contrast-600);
    --cnvs-range-slider-minmax_bg_color: var(--cnvs-range-slider-line_color);
    --cnvs-range-slider-label_color_1: var(--cnvs-range-slider-bar_color);
    --cnvs-range-slider-label_color_2: white;
    --cnvs-range-slider-grid_color_1: var(--cnvs-range-slider-line_color);
    --cnvs-range-slider-grid_color_2: var(--cnvs-range-slider-minmax_text_color);

	position: relative;
    display: block;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	font-size: 12px;
	font-family: Arial, sans-serif;

	&-line {
		position: relative;
        display: block;
		overflow: hidden;
		outline: none !important;
	}

	&-bar {
		position: absolute;
        display: block;
		left: 0;
		width: 0;
	}

	&-shadow {
		position: absolute;
		display: none;
		left: 0;
		width: 0;
	}

	&-handle {
		position: absolute;
        display: block;
		box-sizing: border-box;
		cursor: default;
		z-index: 1;
		&.type_last {
			z-index: 2;
		}
	}

	&-min,
	&-max {
		position: absolute;
        display: block;
		cursor: default;
	}

	&-min {
		left: 0;
	}

	&-max {
		right: 0;
	}

	&-from,
	&-to,
	&-single {
		position: absolute;
        display: block;
		top: 0;
		left: 0;
		cursor: default;
		white-space: nowrap;
	}

	&-grid {
		position: absolute;
		display: none;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20px;

		.irs-with-grid & {
			display: block;
		}

		&-pol {
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 8px;
			background: #000;

			&.small {
				height: 4px;
			}
		}

		&-text {
			position: absolute;
			bottom: 0;
			left: 0;
			white-space: nowrap;
			text-align: center;
			font-size: 9px;
			line-height: 9px;
			padding: 0 3px;
			color: #000;
		}
	}

	&-disable-mask {
		position: absolute;
        display: block;
		top: 0;
		left: -1%;
		width: 102%;
		height: 100%;
		cursor: default;
		background: rgba(0, 0, 0, 0.0);
		z-index: 2;

		.lt-ie9 & {
			background: #000;
			filter: alpha(opacity=0);
			cursor: not-allowed;
		}
	}

	&-disabled {
		opacity: 0.4;
	}

	&-hidden-input {
		position: absolute !important;
		display: block !important;
		top: 0 !important;
		left: 0 !important;
		width: 0 !important;
		height: 0 !important;
		font-size: 0 !important;
		line-height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		overflow: hidden;
		outline: none !important;
		z-index: -9999 !important;
		background: none !important;
		border-style: solid !important;
		border-color: transparent !important;
	}
}


.irs--flat {
	height: 40px;

	&.irs-with-grid {
		height: 60px;
	}

	.irs-line {
		top: var(--cnvs-range-slider-top);
		height: var(--cnvs-range-slider-line_height);
		background-color: var(--cnvs-range-slider-line_color);
		border-radius: var(--cnvs-range-slider-custom_radius);
	}

	.irs-bar {
		top: var(--cnvs-range-slider-top);
		height: var(--cnvs-range-slider-line_height);
		background-color: var(--cnvs-range-slider-bar_color);

		&--single {
			border-radius: var(--cnvs-range-slider-custom_radius) 0 0 var(--cnvs-range-slider-custom_radius);
		}
	}

	.irs-shadow {
		height: 1px;
		bottom: var(--cnvs-range-slider-bottom);
		background-color: var(--cnvs-range-slider-line_color);
	}

	.irs-handle {
		top: 22px;
		width: var(--cnvs-range-slider-handle_width);
		height: var(--cnvs-range-slider-handle_height);
		background-color: transparent;

		&>i:first-child {
			position: absolute;
			display: block;
			top: 0;
			left: 50%;
			width: 2px;
			height: 100%;
			margin-left: -1px;
			background-color: var(--cnvs-range-slider-handle_color_1);
		}

		&.state_hover,
		&:hover {
			&>i:first-child {
				background-color: var(--cnvs-range-slider-handle_color_2);
			}
		}
	}

	.irs-min,
	.irs-max {
		top: 0;
		padding: 1px 3px;
		color: var(--cnvs-range-slider-minmax_text_color);
		font-size: 10px;
		line-height: 1.333;
		text-shadow: none;
		background-color: var(--cnvs-range-slider-minmax_bg_color);
		border-radius: var(--cnvs-range-slider-custom_radius);
	}

	.irs-from,
	.irs-to,
	.irs-single {
		color: var(--cnvs-range-slider-label_color_2);
		font-size: 10px;
		line-height: 1.333;
		text-shadow: none;
		padding: 1px 5px;
		background-color: var(--cnvs-range-slider-label_color_1);
		border-radius: var(--cnvs-range-slider-custom_radius);

		&:before {
			position: absolute;
			display: block;
			content: "";
			bottom: -6px;
			left: 50%;
			width: 0;
			height: 0;
			margin-left: -3px;
			overflow: hidden;
			border: 3px solid transparent;
			border-top-color: var(--cnvs-range-slider-label_color_1);
		}
	}

	.irs-grid {
		&-pol {
			background-color: var(--cnvs-range-slider-grid_color_1);
		}

		&-text {
			color: var(--cnvs-range-slider-grid_color_2);
		}
	}
}